<template>
    <v-layout row class="py-0 my-0">
        <v-flex class="my-0 pt-0 col-8 d-flex flex-row">
            <v-icon class="page__grab-icon">mdi-arrow-all</v-icon>
            <v-textarea
                    :rules="[...validationRules.paragraph]"
                    :value="clause.Clause.title"
                    class="ml-3"
                    dense
                    solo
                    hide-details="auto"
                    @change="clause.Clause.title = $event"
                    v-if="(clause.Clause.id == 10841 || detectNewline(clause.Clause.title) !== undefined) && itemGroup == 'presentation'"
            />
            <v-text-field
                    :rules="[...validationRules.paragraph]"
                    :value="clause.Clause.title"
                    class="ml-3"
                    dense
                    solo
                    hide-details="auto"
                    @change="clause.Clause.title = $event"
                    v-else
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon @click="deleteThisClause(itemGroup, clause.index, clause.Clause.id)" v-on="on">
                        <v-icon color="grey">delete</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('message.deleteThisClause') }}</span>
            </v-tooltip>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import detectNewline from 'detect-newline';

export default {
    name: "SdaClause",
    props: ['clause','itemGroup','idx','updateMode'],
    data() {
        return {}
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('sda',{
            Sda__id: 'Sda.id'
        }, 'statePrefix'),
        ...mapGetters([
            'validationRules'
        ])
    },
    methods: {
        ...mapActions('sdaclause',{
            deleteClauseFromList: 'deleteClauseFromList',
            deleteClause: 'deleteClause',
            getClausesBySda: 'getClausesBySda'
        }),
        async deleteThisClause(itemGroup, itemIndex,itemId) {
            let _this = this
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteClauseAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    let payload = {
                        group: itemGroup,
                        index: itemIndex,
                        prefix: _this.statePrefix
                    }
                    this.deleteClauseFromList(payload)
                    resolve('ok')
                });
                promise.then(()=>{
                    if (itemId != null && this.updateMode == true) {
                        this.deleteClause({
                            sda_id: this.Sda__id,
                            clause_id: itemId
                        }).then(()=>{
                            //TODO reload update clause
                            this.getClausesBySda(this.Sda__id)
                        })
                    }
                })
            }
        },
        detectNewline
    },
}
</script>

<style scoped>

</style>