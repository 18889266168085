<template>
    <div>
        <h5>{{ $t('message.sdaStandardDocuments') }}</h5>
        <draggable v-model="Clauses__standard" @start="drag=true" @end="drag=false" @change="recalculateIndex('standard')">
            <SdaClause
                    :clause="clause"
                    :update-mode="updateMode"
                    :idx="clause.index"
                    item-group="standard"
                    v-bind:key="clause.index"
                    v-for="clause in Clauses__standard"
            />
        </draggable>
        <h5>{{ $t('message.sdaCountryCustomerDocuments') }}</h5>
        <draggable v-model="Clauses__specific" @start="drag=true" @end="drag=false" @change="recalculateIndex('specific')">
            <SdaClause
                    :clause="clause"
                    :update-mode="updateMode"
                    :idx="clause.index"
                    item-group="specific"
                    v-bind:key="clause.index"
                    v-for="clause in Clauses__specific"
            />
        </draggable>
        <h5>{{ $t('message.sdaOtherRequests') }}</h5>
        <draggable v-model="Clauses__other" @start="drag=true" @end="drag=false" @change="recalculateIndex('other')">
            <SdaClause
                    :clause="clause"
                    :update-mode="updateMode"
                    :idx="clause.index"
                    item-group="other"
                    v-bind:key="clause.index"
                    v-for="clause in Clauses__other"
            />
        </draggable>
        <h5>{{ $t('message.sdaPresentationDocuments') }}</h5>
        <draggable v-model="Clauses__presentation" @start="drag=true" @end="drag=false" @change="recalculateIndex('presentation')">
            <SdaClause
                    :clause="clause"
                    :update-mode="updateMode"
                    :idx="clause.index"
                    item-group="presentation"
                    v-bind:key="clause.index"
                    v-for="clause in Clauses__presentation"
            />
        </draggable>
        <v-divider class="mt-0"/>
        <v-layout row>
            <v-flex class="my-0 pt-0 col-8">
                <v-autocomplete
                        :items="defaultClausesList"
                        clearable
                        dense
                        hide-details
                        return-object
                        solo
                        v-model="newStandardClause"
                />
            </v-flex>
            <v-flex class="my-0 pt-0 col-lg-2 col-4">
                <v-btn class="auto-width" @click="addNewClause('standard')"><v-icon>add</v-icon> {{ $t('message.addStandardClause') }}</v-btn>
            </v-flex>
        </v-layout>
        <v-layout row v-if="selectedStandardClause == 10841">
            <v-flex class="my-0 pt-0 col-8">
                <v-select
                        :placeholder="$t('message.countrySpecificDocumentDeliveryAddresses')"
                        :items="countrySpecificDocumentDeliveryAddresses"
                        clearable
                        dense
                        hide-details
                        item-value="SdaAddress.id"
                        item-text="SdaAddress.description"
                        solo
                        v-model="newDocumentDeliveryAddress"
                >
                    <template v-slot:item="{ item }">
                        <span>{{ item.SdaAddress.description}}</span>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div class="d-flex flex-column">
                            <span class="font-xs font-weight-bold" v-html="purify(item.SdaAddress.remarks)"></span>
                            <span class="font-xs" v-html="purify(item.SdaAddress.building)"></span>
                            <span class="font-xs" v-html="purify(item.SdaAddress.street)"></span>
                            <span class="font-xs" v-html="purify(item.SdaAddress.city + ' ' + item.SdaAddress.postcode)"></span>
                            <span class="font-xs" v-html="purify(item.SdaAddress.country)"></span>
                            <span class="font-xs" v-html="$t('message.attention') + ': ' + purify(item.SdaAddress.attention)"></span>
                            <span class="font-xs" v-html="$t('message.phone') + ': ' + purify(item.SdaAddress.phone)"></span>
                        </div>
                    </template>
                </v-select>
            </v-flex>
        </v-layout>
        <v-form lazy-validation ref="newClauseForm" v-model="validForm">
            <v-layout row pt-3>
                <v-flex class="my-0 pt-0 col-8 d-flex flex-row">
                    <v-select
                            :items="defaultGroups"
                            class="col-4"
                            dense
                            hide-details
                            solo
                            v-model="newCustomClauseGroup"
                    >
                        <template v-slot:item="{item}"><span class="font-xs">{{ $t(item.text) }}</span></template>
                        <template v-slot:selection="{item}"><span class="font-xs">{{ $t(item.text) }}</span></template>
                    </v-select>
                    <v-lazy class="col-8 pa-0 mt-0">
                        <v-textarea
                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                :value="newCustomClause"
                                auto-grow
                                class="ml-3"
                                clearablele
                                dense
                                rows="1"
                                solo
                                hide-details="auto"
                                @change="newCustomClause = $event"
                        />
                    </v-lazy>
                </v-flex>
                <v-flex class="my-0 pt-0 col-lg-2 col-4">
                    <v-btn class="auto-width" @click="addNewClause('custom')"><v-icon>add</v-icon> {{ $t('message.addCustomClause') }}</v-btn>
                </v-flex>
            </v-layout>
        </v-form>
        <AlertMissingValues
            :alert_message="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
    </div>
</template>

<script>
import AlertMissingValues from "Components/Appic/AlertMissingValues";
import draggable from 'vuedraggable'
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import SdaClause from "Components/Appic/SdaClause";
import {mapActions, mapGetters} from "vuex";
import {api} from "Api";
import {purify} from "Helpers/helpers"

export default {
    name: "SdaClauseList",
    components: {draggable, SdaClause, AlertMissingValues},
    props: ['updateMode'],
    data() {
        return {
            countrySpecificDocumentDeliveryAddresses: [],
            defaultClausesList: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            newCustomClause: null,
            newCustomClauseGroup: 1,
            newDocumentDeliveryAddress: null,
            newStandardClause: null,
            selectedStandardClause: null,
            validForm: false
        }
    },
    computed: {
        statePrefix () {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('sdaclause',{
            Clauses__standard: 'Clauses.standard',
            Clauses__specific: 'Clauses.specific',
            Clauses__other: 'Clauses.other',
            Clauses__presentation: 'Clauses.presentation'
        },'statePrefix'),
        ...mapFieldsPrefixed('sda',{
            Sda__contract_id: 'Sda.contract_id',
            Sda__doc_deliveryaddress_id: 'Sda.doc_deliveryaddress_id'
        },'statePrefix'),
        ...mapFields('sdaclause',{
            defaultClauses__standard: 'defaultClauses.standard',
            defaultClauses__specific: 'defaultClauses.specific',
            defaultClauses__other: 'defaultClauses.other',
            defaultClauses__presentation: 'defaultClauses.presentation',
            defaultGroups: 'defaultGroups'
        }),
        ...mapGetters([
            'validationRules'
        ])
    },
    methods: {
        ...mapActions('sdaclause',{
            addClauseToList: 'addClauseToList',
            resetDefaultGroups: 'resetDefaultGroups'
        }),
        addNewClause (type) {
            if(this.$refs.newClauseForm.validate()) {
                switch (type) {
                    case 'standard':
                        if (this.newStandardClause == null) {
                            this.dialogs.error_message = this.$t('message.errors.noClauseSelected');
                            this.dialogs.error = true;
                        } else {
                            if (this.newStandardClause.value == 10841) {
                                if (this.newDocumentDeliveryAddress == null) {
                                    this.dialogs.error_message = this.$t('message.errors.noDeliveryAddressSelected');
                                    this.dialogs.error = true;
                                } else {
                                    //replace %customeraddress% in clause text
                                    let addressObj = this.countrySpecificDocumentDeliveryAddresses.find(a => a.SdaAddress.id == this.newDocumentDeliveryAddress).SdaAddress
                                    let address = ''
                                    address += addressObj.remarks + "\n"
                                    address += addressObj.building + "\n"
                                    address += addressObj.street + "\n"
                                    address += addressObj.city + ' ' + addressObj.postcode + "\n"
                                    address += addressObj.country + "\n"
                                    address += this.$t('message.attention') + ': ' + addressObj.attention + "\n"
                                    address += this.$t('message.phone') + ': ' + addressObj.phone + "\n"
                                    if (address.length > 0) address = "\n" + address

                                    this.newStandardClause.text = this.newStandardClause.text.replaceAll('%customeraddress%', address)

                                    let payload = {
                                        id: this.newStandardClause.value,
                                        group: this.newStandardClause.group,
                                        prefix: this.statePrefix,
                                        value: this.newStandardClause.text
                                    }
                                    this.addClauseToList(payload)
                                    this.Sda__doc_deliveryaddress_id = this.newDocumentDeliveryAddress
                                    this.newStandardClause = null
                                }
                            } else {
                                let payload = {
                                    id: this.newStandardClause.value,
                                    group: this.newStandardClause.group,
                                    prefix: this.statePrefix,
                                    value: this.newStandardClause.text
                                }
                                this.addClauseToList(payload)
                                this.newStandardClause = null
                            }
                        }
                        break;
                    case 'custom':
                        if ((this.newCustomClause == null || this.newCustomClause.length == 0) && this.newCustomClauseGroup != null) {
                            this.dialogs.error_message = this.$t('message.errors.noClauseEntered');
                            this.dialogs.error = true;
                        } else {
                            let payload = {
                                id: null,
                                group: this.newCustomClauseGroup,
                                prefix: this.statePrefix,
                                value: this.newCustomClause
                            }
                            this.addClauseToList(payload)
                            this.newCustomClause = null
                        }
                        break;
                }
            } else {
                this.$toast.error(this.$t('message.errors.clauseNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        purify,
        setDefaultClausesList () {
            this.defaultClausesList = []
            this.defaultClausesList.push({'header': this.$t('message.sdaStandardDocuments') })
            this.defaultClauses__standard.forEach(clause => {
                this.defaultClausesList.push({
                    value: clause.Clause.id,
                    text: clause.Clause.title,
                    group: clause.Clause.sdadoctype_id
                })
            })

            this.defaultClausesList.push({'header': this.$t('message.sdaCountryCustomerDocuments') })
            this.defaultClauses__specific.forEach(clause => {
                this.defaultClausesList.push({
                    value: clause.Clause.id,
                    text: clause.Clause.title,
                    group: clause.Clause.sdadoctype_id
                })
            })

            this.defaultClausesList.push({'header': this.$t('message.sdaOtherRequests') })
            this.defaultClauses__other.forEach(clause => {
                this.defaultClausesList.push({
                    value: clause.Clause.id,
                    text: clause.Clause.title,
                    group: clause.Clause.sdadoctype_id
                })
            })

            this.defaultClausesList.push({'header': this.$t('message.sdaPresentationDocuments') })
            this.defaultClauses__presentation.forEach(clause => {
                this.defaultClausesList.push({
                    value: clause.Clause.id,
                    text: clause.Clause.title,
                    group: clause.Clause.sdadoctype_id
                })
            })
        },
        recalculateIndex (itemGroup) {
            let payload = {
                group: itemGroup,
                prefix: this.statePrefix
            }
            this.$store.commit('sdaclause/reCalculateListIndices',payload)
        }
    },
    watch: {
        newStandardClause: function(obj){
            if(obj != null){
                this.selectedStandardClause = obj?.value
                if(obj?.value == 10841){
                    api
                        .get('/contracts/' + this.Sda__contract_id + '/country-document-delivery-address')
                        .then((response) => {
                            this.countrySpecificDocumentDeliveryAddresses = response.data.addresses
                            this.newDocumentDeliveryAddress = response.data.address_id
                        }).catch(() => {

                        })
                }
            } else {
                this.selectedStandardClause = null
            }
        }
    },
    mounted() {
        // this.resetDefaultGroups()
        this.setDefaultClausesList()
    }
}
</script>

<style scoped>

</style>