<template>
    <v-dialog
            v-model="openDialog"
            color="red"
            max-width="290"
            width="290"
            @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar color="red" dark dense flat>
                <v-toolbar-title class="white--text"><v-icon x-large>error_outline</v-icon> {{$t('message.oops')}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4">
                <span>{{this.alert_message}}</span>
                <v-list
                    dense
                >
                    <v-list-item
                            v-for="(error, error_index) in this.missing_values"
                            v-bind:key="error_index"
                    >
                        <v-icon small>star</v-icon><span class="ml-3">{{ error }}</span>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn @click.native="cancel" color="grey" text>{{$t('message.dismiss')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AlertMissingValues",
        data(){
            return {
                open_dialog: false
            }
        },
        props: ['missing_values','alert_message','dialog','dialog-closed'],
        computed: {
            openDialog() {
                return this.open_dialog;
            }
        },
        methods: {
            cancel() {
                this.open_dialog = false
                this.$emit('dialog-closed')
            }
        },
        watch: {
            dialog(value) {
                this.open_dialog = value
            }
        }
    }
</script>

<style scoped>

</style>